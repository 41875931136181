@import 'colours.scss';

@mixin events-button {
    margin-top: var(--spacing-small);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 30px;
    width: fit-content;
    background: var(--colour-background-accent-secondary);
    color: var(--colour-link);
    :hover {
        color: var(--colour-link-active);
    }
    font-weight: bold;
    font-size: var(--font-size-large);
    border: none;    
    border-radius: var(--spacing-small);
    text-wrap: wrap;
    p {
        margin: 0;
        padding: 0;
    }
    @media ( max-width: 500px ) {
        width: 100%;
    }
}