.LatestIssue {
    > ul {
        display: flex;
        flex-direction: row;
        @media ( max-width: 360px ) {
            flex-direction: column;
        }
        align-items: flex-start;
        justify-content: flex-start;
        list-style-type: none;
        padding-left: 0;
        padding-bottom: 0;
        margin-top: var(--spacing-small);
        gap: var(--spacing-small);
        > li {
            width: auto;
            height: 46px;
            img {
                transform: none !important;
            }
        }
    }
}
