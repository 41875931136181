@use 'styles/magazine-button' as *;

.Details {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 600px) {
        align-items: center;
    }
    justify-content: flex-start;
    margin-bottom: var(--spacing-small);
}

.Button {
    @include magazine-button;
}