
@use 'styles/energy-heading' as *;

.Header {
    align-self: flex-start;
    width: 100%;
    h1 {
        [data-site='materials-energy-expo'] & {
            @include energy-heading;
        }
        > a {
            color: var(--colour-text-accent-primary);
            &:hover { color: var(--colour-text-accent-secondary); }
        }
    }
    > :not(h1):last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    [data-header-align='left'] & {
        text-align: left;
    }
    [data-header-align='center'] & {
        text-align: center;
    }
    [data-header-align='right'] & {
        text-align: right;
    }
}
