@use 'styles/magazine-button' as *;
.Featured {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    padding-top: var(--spacing-medium-three-quarters);
    max-width: 100vw;
    a:first-of-type {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        max-width: 100vw;
        font-size: var(--font-size-large);
        [data-site='magazines'] & {
            font-size: var(--font-size-medium)
        }
        line-height: 1.2em;
        color: var(--colour-text-accent-primary);
        > picture,
        > div,
        > span {
            height: 250px;
            [data-site='magazines'] & {
                height: auto;
                aspect-ratio: 7/10;
                @media (max-width: 495px) {
                    display: none;
                }
                margin-bottom: 0;
            }
            width: 100%;
            max-width: 100vw;
            box-shadow: var(--box-shadow-mid);
            margin-bottom: var(--spacing-small);
            background: var(--colour-background-primary);
            > video {
                height: 250px;
                width: 100%;
                object-fit: cover;
            }
            svg {
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    a ~ a:last-child {
        margin-bottom: var(--spacing-small);
        align-self: start;
    }

    p { padding-bottom: 0; }

    [data-site="magazines"] & {
        padding-top: var(--spacing-small);
        @media (max-width: 495px) {
            padding-top: 0;
            align-items: center;
        }
        a {
            display: flex;
            flex-direction: column-reverse !important;
            gap: var(--spacing-small) !important;
            align-items: center;
            color: var(--colour-text-primary);
            @media (max-width: 495px) {
                @include magazine-button;
            }
        }
    }
}

.Metadata {
    position: absolute;
    top: 4px;
    left: 0;
}