@use 'styles/magazine-heading' as *;

.List {

    padding: 0 var(--page-padding-horizontal);
    [data-site='magazines'] & {
        padding: var(--spacing-small) var(--page-padding-horizontal);
    }
    overflow: hidden;

    > ul {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        @media ( max-width: 1200px ) {
            grid-template-columns: 50% 50%;
        }
        @media ( max-width: 600px ) {
            grid-template-columns: 100%;
        }
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
            max-width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            a {
                display: block;
                width: 100%;
            }
        }
    }

    &[data-type='Expanded'] {
        padding: var(--spacing-small) var(--page-padding-horizontal);
        > ul { 
            display: grid;
            gap: var(--spacing-small);
            background: var(--background);
            padding: var(--spacing-medium);
            @media (min-width: 600px) {
                grid-template-columns: repeat(2,minmax(45%,1fr));
            }
            @media (max-width: 400px) {
                grid-template-columns: 100%;
            }
            
            li { 
                a {
                    display: contents;
                    width: auto;
                }
            }
        }
    }
}

.Text {
    h1 {
        @include magazine-heading;
        margin-bottom: var(--spacing-small);
    }
}