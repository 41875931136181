@use 'styles/magazine-heading' as *;
@use 'styles/magazine-button' as *;

.Archive {
    > h1 {
        @include magazine-heading;
    }
}

.Links { 
    a {
        @include magazine-button;
    }

    p {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-small);
    }

    a:nth-child(odd) {
        background: var(--colour-background-accent-primary);
    }
    a:nth-child(even) {
        background: var(--colour-background-accent-secondary);
    }
}