.Archive {
    > details > ul {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
        gap: var(--spacing-medium) var(--spacing-small);
        list-style-type: none;
        @media ( max-width: 600px ) {
            grid-template-columns: 100%;
            gap: var(--spacing-small-half);
            padding-left: 0;
        }
    }
}
