.ChannelList {

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    header {

        margin: 0 0 1em 0;
        padding: 0;

        h1 {
            margin: 0;
            font-size: var(--font-size-normal);
        }

    }

}

.SocialMediaChannels {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 0;
    list-style-type: none;
    padding: 0;

    gap: var(--spacing-small);

    > li {
        margin: 0;
        picture, img, svg {
            width: 40px;
            height: 40px;
        }
        picture, img { filter: invert(1); }
        svg {
            fill: var(--colour-text-secondary);
        }
    }

}

.Header {
    p {
        margin: 0;
        padding: 0;
    }
}

.Image {
    width: 40px;
    height: 40px;
}