.ComponentBodyCallToAction {

    position: relative;
    min-height: min-content;

    display: grid;

    @media ( min-width: 0px ) {
        grid-template-columns: 100%;
        grid-template-rows: min-content min-content;
    }
    @media ( min-width: 1100px ) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content min-content;
    }

    padding-top: var(--spacing-medium) !important;
    padding-bottom: var(--spacing-medium) !important;

    [data-site='main'] & {
        > * { filter: var(--filter-shadow-mid); }
    }

    [data-type='MEE_Full_Bleed'] + & {
        padding-top: 0 !important;
    }

    [data-site='materials-energy-expo'] & {
        &[data-background] {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    [data-site='magazines'] & {
        gap: var(--spacing-medium);
    }

    &[data-image-side='left'] {
        direction: rtl !important;
    }
    &[data-image-side='right'] {
        direction: ltr !important;
    }
    >* {
        direction: ltr !important;
    }

}
