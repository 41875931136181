@use 'styles/energy-heading' as *;
@use 'styles/magazine-heading' as *;
@use 'styles/events-button' as *;

// NOTE: Remove duplication between this and topic blocks

.ContentBlock {

    display: flex;
    position: relative;

    [data-site='materials-energy-expo'] [data-type='Expanded'] & {
        &[data-sizing-mode='Contain'] {
            padding-top: var(--spacing-small-half);
            padding-bottom: var(--spacing-small-half);
        }
    }

    [data-type='Compact'] &,
    [data-type='Light'] & {
        height: 100%;
        flex-direction: column;
        [data-site='materials-energy-expo'] & {
            border-bottom-left-radius: var(--spacing-medium-three-quarters);
            h1 {
                text-align: center;
                text-transform: uppercase;
                font-weight: normal;
                font-size: var(--font-size-featured);
            }
        }
    }

    [data-type='Compact'] &,
    [data-type='Slideshow'] & {
        background: var(--colour-background-accent-primary);
        [data-site='magazines'] & {
            background: var(--colour-background-accent-secondary);
        }
    }

    [data-type='Light'] & {
        background: var(--colour-background-primary);
        padding: var(--spacing-small);
        [data-site='materials-energy-expo'] & {
            padding: 0;
        }
        h1 {
            padding-bottom: var(--spacing-small-half);
            margin-bottom: var(--spacing-small-half);
            border-bottom: 1px solid var(--colour-text-accent-primary);
        }
        [data-site='main'] & {
            p + ul { margin-top: -2em; }
        }
        li {
            > strong { display: block; }
            margin-bottom: 1em;
            &:last-child { margin-bottom: 0; }
        }
    }

    [data-type='Expanded'] &,
    [data-type='Slideshow'] &,
    [data-type='Testimonials'] & {
        display: grid;
        //grid-template-columns: 1fr 2fr;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-medium) var(--spacing-large);
        align-items: center;
    }
    [data-type='Expanded'] & {
        [data-site='events'] & {
            grid-template-columns: 1fr 2fr;
            @media ( max-width: 900px ) {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
            }
        }
    }

    [data-type='Slideshow'] &,
    [data-type='Testimonials'] {
        width: 100%;
        min-width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
    }

    [data-type='Icons'] & {
        width: 100%;
        height: 100%;
        padding: var(--spacing-small);
        background: var(--colour-background-secondary);
        display: grid;
        gap: var(--spacing-small) var(--spacing-small-half);
        @media( min-width: 0px ) {
            grid-template-areas:
                'icon'
                'text';
            grid-template-columns: 1fr;
            grid-template-rows: 100px 1fr;
        }
        @media( min-width: 350px ) {
            grid-template-areas: "icon text";
            grid-template-columns: 100px 1fr;
            grid-template-rows: 1fr;
        }
        align-items: start;
        .ImageCell { grid-area: icon; }
        .Text {
            grid-area: text;
            h1, h2, h3 { white-space: nowrap; }
            p { color: var(--colour-text-primary); }
        }
        [data-site='events'] & {
            grid-template-areas:
                'icon'
                'text';
            align-items: center;
            justify-content: center;
            grid-template-columns: 1fr;
            grid-template-rows: min-content max-content;
            gap: var(--spacing-small-half) var(--spacing-small);
            p {
                font-size: var(--font-size-large);
                font-weight: bold;
            }
            [data-inlineheader='true'] & {
                @media ( max-width: 380px ) {
                    grid-template-areas:
                    "icon"
                    "text";
                    width: 100%;
                    grid-template-columns: 100%;
                    grid-template-rows: fit-content fit-content;
                    gap: var(--spacing-small-half);
                }
            }
        }
    }

    [data-type='IconsNoText'] & {
        justify-content: center;
        .Text {
            display: none;
        }
    }

    [data-type='Expanded'] & {
        &[data-image-side='left'] {
            direction: ltr !important;
        }
        &[data-image-side='right'] {
            direction: rtl !important;
        }
        >section {
            direction: ltr !important;
        }
    }

    [data-number='1'] & {
        [data-type='Light'] &,
        [data-type='Slideshow'] &,
        [data-type='Testimonials'] & {
            [data-site='events'] & {
                padding: var(--spacing-medium) var(--page-padding-horizontal);
            }
        }
        [data-type='Compact'] & {
            [data-site='events'] & {
                padding: var(--spacing-medium) calc(2 * var(--page-padding-horizontal));
            }
        }
    }

    &[data-background-image='true'] {
        background: none;
    }
}

.ImageCell {

    position: relative;

    [data-type='Compact'] &,
    [data-type='Light'] & {
        height: 250px;
        min-width: 100%;
        background: var(--colour-background-primary);
    }

    [data-type='Compact'] & {
        [data-site='magazines'] & {
            @media (max-width: 646px) {
                display: none;
            }
        }
    }

    [data-type='Light'] &,
    [data-type='Icons'] & {
        aspect-ratio: 4 / 3;
        min-height: auto;
        &[data-mime='image/svg+xml'] {
            height: 100px;
            aspect-ratio: 1 / 1;
        }
        svg {
            width: 100px !important;
            height: 100px !important;
            min-width: 100px !important;
            min-height: 100px !important;
        }
    }

    [data-type='Light'] * {
        svg { margin-left: calc( 50% - 50px); }
    }

    [data-type='Expanded'] &,
    [data-type='Slideshow'] & {
        width: 100%;
        height: 100%;
        min-height: 300px;
        align-self: flex-start;
        filter: var(--filter-shadow-mid);
        background: var(--colour-background-primary);
        [data-site='events'] & ,
        [data-site='magazines'] & {
            filter: none;
            background: transparent;
        }
        [data-site='events'] & {
            min-height: auto;
        }
    }
    [data-mobile-image='shown'] & {
        [data-type='Expanded'] & {
            [data-site='events'] & {
                min-height: 100px;
            }
        }
    }

    [data-type='Testimonials'] & {
        width: 100%;
        height: 100%;
        min-height: 300px;
        align-self: flex-start;
    }

    [data-site='materials-energy-expo'] [data-type='Expanded'] & {
        @media ( min-width: var(--card-min-width) ) {
            min-height: auto;
        }
    }

    // [data-type='Expanded'] & {
    //     @media ( min-width: 680px ) and ( max-width: 1049px ) { // Min width is one column, max is when header becomes sticky
    //         position: sticky;
    //         top: var(--spacing-medium);
    //     }
    // }

    [data-type='Icons'] & {
        svg g * {
            stroke: var(--colour-text-accent-primary) !important;
        }
    }

    [data-type='IconsNoText'] & {
        aspect-ratio: 4 / 3;
        min-height: min-content;
        &[data-mime='image/svg+xml'] {
            height: 200px;
            aspect-ratio: 1 / 1;
            @media ( max-width: 500px ) {
                height: 120px;
            }
        }
        svg {
            width: 200px !important;
            height: 200px !important;
            min-width: 100px !important;
            min-height: 100px !important;
        }
    }
}

.Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    max-width: 32ch;
    height: 100%;
    min-height: 100%;
    [data-type='IconsNoText'],
    [data-type='Icons'] & {
        color: var(--colour-text-accent-primary);
        fill: var(--colour-text-accent-primary);
    }
    [data-site='materials-energy-expo'] [data-type='Expanded'] & img {
        background: white;
    }
}

.Text {

    padding: 0;

    [data-align='left'] & {
        text-align: start !important;
    }
    [data-align='right'] & {
        text-align: end !important;
    }
    [data-align='center'] & {
        text-align: center !important;
    }

    [data-image-side='right'],
    [data-image-side='left'] & {
        direction: ltr !important;
    }

    [data-site='events'] & {
        direction: ltr !important;
        a {
            @include events-button;
        }
    }

    &:first-child:last-child {
        columns: auto 50ch;
        column-gap: var(--spacing-large);
        column-rule: 1px dotted var(--colour-text-accent-primary);
        > * { break-inside: avoid; }
        > :first-child {
            column-span: all;
        }
    }

    [data-site='main'] {
        column-width: 32ch;
    }
    [data-type='icons'] & [data-site='materials-energy-expo'] {
        column-width: auto;
    }

    column-gap: 4em;

    h1 {
        margin-top: 0;
        column-span: all;
        margin-bottom: var(--spacing-small-half) !important;
        padding-bottom: var(--spacing-small-half) !important;
        border-bottom: 1px solid var(--colour-text-accent-primary);
        [data-site='events'] & {
            border-bottom: none !important;
        }
    }

    h1 + * {
        margin-top: 0 !important;
    }

    ul { padding-left: 14px; }
    li {
        list-style-type: square;
        &::marker {
            color: var(--colour-text-accent-primary);
        }
    }

    [data-site='materials-energy-expo'] & {
        p + ul { margin-top: -1.6em; }
    }

    p { margin: 0; }

    > :last-child {
        margin-bottom: 0 !important;
    }

    > div > :last-child {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    [data-type='Compact'] &,
    [data-type='Slideshow'] & {
        padding: var(--spacing-small) !important;
        color: var(--colour-text-tertiary);
        [data-site='magazines'] & ,
        [data-site='main'] & {
            --colour-link: var(--colour-text-secondary);
            --colour-link-active: var(--colour-text-accent-primary);
        }
        [data-site='magazines'] & {
            color: var(--colour-text-secondary);
            text-align: center;
        }
        h1 {
            color: var(--colour-text-secondary);
            font-size: var(--font-size-large);
            [data-site='events'] & {
                border-bottom: none !important;
                font-size: var(--font-size-header);
            }
            [data-site='magazines'] & {
                border-bottom: none !important;
                align-self: center;
            }
        }
        li a {
            color: var(--colour-text-accent-primary);
            &:hover { color: var(--colour-text-secondary); }
        }
    }

    [data-type='Testimonials'] & {
        padding: var(--spacing-medium) !important;
    }

    [data-type='Light'] & {
        display: flex;
        flex-direction: column;
        padding: var(--spacing-small) 0 0 0  !important;
        h1 { font-size: var(--font-size-large); }
        > div > p > a:not([href^='mailto:']) {
            [data-site='materials-energy-expo'] & {
                align-self: flex-start;
                color: #ebebeb !important;
                &:hover {
                    color: white !important;
                }
            }
        }
    }

    [data-type='Light'] &,
    [data-type='Compact'] & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        > div {
            display: flex;
            height: 100%;
            flex-grow: 1;
            flex-direction: column;
            > :last-child {
                flex-grow: 1;
            }
        }
        p:has(a:first-child:last-child) {
            flex-grow: 1;
            justify-self: flex-end;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            [data-site='events'] & {
                display: block;
                flex-grow: 0;
                margin-left: auto;
                margin-right: auto;
                > a {
                    display: inline !important;
                    @include events-button;
                    margin-top: 0;
                    padding: 10px 30px;
                    border-radius: var(--spacing-small);
                }
            }
        }
       p:has(a:first-child:last-child) ~ p:has(a:first-child:last-child) {
            flex-grow: 0;
            > a { margin-top: 0; }
        }
        button {
            [data-site='events'] & {
                @include events-button;
                display: inline-block;
                padding: 10px 20px;
            }
        }
    }

    [data-type='Expanded'] & {
        padding: 0;
        [data-site='materials-energy-expo'] & {
            padding-top: var(--spacing-medium);
            padding-bottom: var(--spacing-medium);
            color: var(--colour-text-primary);
        }
        font-size: var(--font-size-medium);
        h1 {
            padding-bottom: 0;
            margin-bottom: 0;
            [data-site='materials-energy-expo'] & {
                @include energy-heading;
                padding-bottom: 0 !important;
                margin-bottom: var(--spacing-medium) !important;
                border-bottom: none !important;
            }
            [data-site='magazines'] & {
                @include magazine-heading;
                padding-bottom: 0 !important;
                margin-bottom: var(--spacing-medium) !important;
                border-bottom: none !important;
            }
        }
        h2 {
            color: var(--colour-text-accent-secondary);
            font-size: var(--font-size-large);
            padding-bottom: 0;
            margin-top: 0;
        }
        h1 + h2 {
            margin-top: 0.5em;
        }
        p {
            margin-top: 0;
            padding-bottom: 0;
            margin-bottom: 2em;
            [data-site='main'] & {
                > strong:first-child:last-child {
                    font-size: var(--font-size-large);
                }
            }
            [data-site='events'] & {
                color: var(--colour-text-primary);
            }
        }
        h1 + p,
        h2 + p {
            margin-top: 2em;
        }
        ul {
            margin-bottom: 1em;
        }
        > :last-child { margin-bottom: 0; }
        button, a {
            [data-site='events'] & {
                @include events-button;
                display: inline-block;
                padding: 10px 20px;
            }
        }
        button:last-of-type {
            [data-site='events'] & {
                background: var(--colour-background-accent-tertiary) !important;
            }
        }
    }

    > div > h1 > a:not([href^='mailto:']) {
        color: inherit;
        &:hover { color: var(--colour-text-primary); }
    }

    > div > p > a:not([href^='mailto:']) {
        font-size: var(--font-size-medium);
        // NOTE: This is duplicated from the button module
        display: inline-block;
        color: var(--colour-link);
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        flex: 0 0 auto;
        padding: 10px 14px 8px 14px;
        margin: 0 1em 0 0 ;
        [data-site='main'] & {
            margin-bottom: 15px;
        }
        border: 1px solid var(--colour-link);
        transition: all 0.35s ease-in-out;
        &:hover {
            border: 1px solid var(--colour-link-active);
            color: var(--colour-link-active);
            transition: all 0.35s ease-in-out;
            //filter: drop-shadow(0 0 8px var(--colour-link-glow));
        }

        [data-site='events'] & {
            @include events-button;
            padding: 10px 30px;
            font-size: var(--font-size-large);
            display: inline-block;
        }

        // Custom
        margin-top: var(--spacing-small);
        line-height: 1;
    }

    h1 > a {
        margin-right: 0;
        margin-left: var(--spacing-small);
        margin-top: 0;
    }

    [data-type='Icons'] & {
        padding: 0 !important;
        padding-bottom: 0 !important;
        > div > :first-child { margin-top: 0; }
        > div > :last-child { margin-bottom: 0; padding-bottom: 0; }
        h1 {
            font-size: var(--font-size-large);
            color: var(--colour-text-accent-primary);
            margin-bottom: 0.5rem;
            white-space: normal !important;
        }
        p { font-size: var(--font-size-small); }
        h1 + p { margin-top: 0; }
        button {
            [data-site='events'] & {
                @include events-button;
                background: var(--colour-background-accent-tertiary);
                font-size: var(--font-size-large);
            }
        }
    }

    [data-type='Testimonials'] & {

        columns: 1;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 !important;

        div {

            max-width: 72ch;

            display: grid !important;
            grid-template-areas:
                "leftmark quotation rightmark"
                "leftempty person rightempty";
            gap: var(--spacing-small);

            h1 {
                grid-area: person;
                @media( min-width: 0px ) {
                    font-size: var(--font-size-medium);
                }
                @media( min-width: 700px ) {
                    font-size: var(--font-size-large);
                }
                border: none;
                text-align: center;
                justify-self: center;
                line-height: 1.6em;
                margin: 0 !important;
                padding: 0 !important;
            }

            p {
                grid-area: quotation;
                align-self: flex-end;
                @media( min-width: 0px ) {
                    font-size: var(--font-size-medium);
                }
                @media( min-width: 700px ) {
                    font-size: var(--font-size-large);
                }
                text-align: center;
            }

            @media ( min-width: 1000px ) {

                &::before {
                    grid-area: leftmark;
                    display: block;
                    position: relative;
                    top: -16px;
                    content: '“';
                    font-size: 128px;
                    line-height: 100%;
                    opacity: 0.3;
                    align-self: flex-start;
                    font-family: Arial, Helvetica, sans-serif;
                }

                &::after {
                    grid-area: rightmark;
                    display: block;
                    position: relative;
                    top: 72px;
                    content: '”';
                    font-size: 128px;
                    line-height: 100%;
                    opacity: 0.3;
                    align-self: flex-end;
                    font-family: Arial, Helvetica, sans-serif;
                }

            }

        }

    }

}

.FormList {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: var(--spacing-small) 0 0 0 !important;
    padding: 0;
    flex-grow: 1;
    > li { list-style-type: none; }
    [data-site='materials-energy-expo'] & {
        justify-content: flex-start;
        padding-left: 0px;
        > li {padding-left: 0px;}
    }
}
