.Browser {

    background: white !important;

    > ul {
        padding: 0;
        > li {
            list-style-type: none;
            margin-bottom: var(--spacing-medium);
        }
    }

}

.Filters {

    display: grid !important;
    justify-content: center;

    @media ( min-width: 0px ) {
        grid-template-areas:
            "type"
            "location"
            "portfolio"
            "topic"
            "time"
            "reset";
        grid-template-columns: 1fr;
        > button { justify-self: center; }
    }
    @media ( min-width: 650px ) {
        grid-template-areas:
            "type portfolio time"
            "location topic reset";
        grid-template-columns: min-content min-content;
    }
    @media ( min-width: 1385px ) {
        grid-template-areas: "type location portfolio topic time reset";
        grid-template-columns: min-content min-content min-content min-content min-content;
    }

    > span {
        min-width: 100%;
        select { min-width: 100%; }
    }

}

.TypeFilter { grid-area: type; }
.LocationFilter { grid-area: location; }
.PortfolioFilter { grid-area: portfolio; }
.TopicFilter { grid-area: topic; }
