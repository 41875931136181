@import 'colours.scss';

@mixin magazine-heading {

    position: relative;
    display: inline-block;
    color: var(--colour-text-primary);
    text-transform: uppercase;
    font-weight: normal;
    font-size: var(--font-size-hero);

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 1.4em;
        height: 0.25em;
        border-bottom: 5px solid var(--colour-background-accent-primary);
        width: 350px;
        @media ( max-width: 500px ) {
            width: 75vw;
        }
    }

    em {
        font-weight: normal;
        font-style: normal;
        color: var(--colour-background-accent-primary);
    }

}
