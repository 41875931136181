.Footer {

    display: grid;
    max-width: 100vw;
    overflow: hidden;

    [data-site='main'] & {
        gap: var(--spacing-medium);
        @media ( min-width: 0px ) and ( max-width: 869px ) {
            grid-template-areas:
                "logo social"
                "links links"
                "locations locations";
            grid-template-columns: min-content 1fr;
            > section:last-of-type {
                width: 100%;
                header { display: none; }
                > ul {
                    flex-direction: row !important;
                    justify-content: flex-end;
                }
            }
            > ul { flex-direction: column; }
        }
        @media ( max-width: 350px ) {
            gap: var(--spacing-small);
            > section > ul { gap: var(--spacing-small-half); }
        }
        @media ( max-width: 699px ) {
            > ul {
                flex-direction: column !important;
            }
        }
        @media ( min-width: 700px ) {
           > ul { flex-direction: row; }
        }
        @media ( min-width: 870px ) {
            grid-template-areas:
                "logo links"
                "social links"
                "locations locations";
        }
        @media ( min-width: 900px ) {
            grid-template-areas:
                "logo links"
                "social links"
                "empty locations";
        }
        @media ( min-width: 1010px ) {
            grid-template-areas:
                "logo empty"
                "links links"
                "social locations";
            grid-template-rows: min-content min-content min-content;
            gap: var(--spacing-small) var(--spacing-medium);
            > section:first-of-type {
                padding-bottom: var(--spacing-small);
                border-bottom: 1px solid var(--colour-text-accent-primary);
                > ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 0 var(--spacing-medium);
                }
            }
        }
        @media ( min-width: 1430px ) {
            grid-template-areas:
                "logo links locations"
                "social links locations";
            grid-template-rows: min-content 1fr;
            gap: var(--spacing-medium);
            > section:first-of-type {
                padding-bottom: 0;
                border-bottom: none;
                > ul { display: block; }
            }
        }
        @media ( min-width: 1580px ) {
            grid-template-areas: "logo links locations social";
            grid-template-rows: 1fr;
        }

        @media ( max-width: 1890px ) {
            > section:last-of-type {
                width: min-content;
                white-space: nowrap;
                header {
                    width: 100%;
                    text-align: center;
                }
                > ul {
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                }
            }
        }
    }

    [data-site='magazines'] & {
        color: var(--colour-text-secondary) !important;
        background: var(--colour-background-accent-primary) !important;
        border: none !important;
        section:first-of-type {
            color: var(--colour-text-primary) !important;
            background: var(--colour-text-secondary) !important;
        }
    }

    [data-site='events'] & {
        color: var(--colour-text-primary) !important;
        background: var(--colour-background-primary) !important;
        border: none !important;
        border-top: 10px solid var(--colour-background-accent-tertiary) !important;
        section:first-of-type {
            color: var(--colour-text-secondary) !important;
            background: var(--colour-background-secondary) !important;
        }
    }

    [data-site='events'] & ,
    [data-site='magazines'] & {

        display: grid;

        @media ( min-width: 0px ) {
            padding: 0;
            padding-bottom: var(--spacing-medium);
            grid-template-areas:
                "links"
                "logo"
                "body"
                "tagline"
                "social";
            gap: var(--spacing-small) var(--spacing-medium);
            > * {
                padding: 0 var(--page-padding-horizontal);
            }
            > section:first-of-type {
                margin: 0;
                padding: var(--spacing-medium);
                > ul {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: var(--spacing-small-half) var(--spacing-medium);
                    > li > a { white-space: nowrap; }
                }
            }
        }
        @media ( min-width: 780px ) {
            grid-template-areas:
                "links links"
                "logo  tagline"
                "body  tagline"
                "body  social";
            grid-template-columns: 1fr 1fr;
            padding: 0 var(--page-padding-horizontal) var(--spacing-small) var(--page-padding-horizontal);
            > * {
                padding: 0;
            }
            > section:first-of-type {
                margin: 0 calc( -1 * var(--page-padding-horizontal) );
                padding: var(--spacing-small-half) var(--page-padding-horizontal);
                > ul {
                    flex-direction: row;
                }
            }
        }
        @media (min-width: 1015px ) {
            padding: var(--spacing-medium) var(--page-padding-horizontal);
            grid-template-areas:
                "logo  links   tagline"
                "body  links   tagline"
                "body  links   social";
            grid-template-columns: 1fr 1fr 1fr;
            > section:first-of-type {
                margin: calc( -1 * var(--spacing-medium) ) 0;
                padding: var(--spacing-medium);
                > ul {
                    flex-direction: column;
                }
            }
        }

        gap: var(--spacing-medium);
        background: var(--colour-background-accent-secondary);
        border-top: 10px solid var(--colour-background-accent-primary);
        border-bottom: 10px solid var(--colour-background-accent-primary);
        color: var(--colour-text-accent-tertiary);

        p {
            margin: 0;
            &:last-child { padding-bottom: 0; }
        }

        > section:first-of-type {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;
            background: var(--colour-text-accent-tertiary);
            [data-site='events'] & { 
                background: var(--colour-background-accent-secondary); 
                color: var(--colour-text-secondary);
            }
            
            a {
                color: var(--colour-text-primary);
                font-size: var(--font-size-header);
                line-height: 3rem;
            }
        }
    }

    [data-site='events'] & ,
    [data-site='magazines'] & { 
        @media ( min-width: 780px ) {
            grid-template-areas: 
            "links links"
            "body tagline"
            "body logo"
            "body social";
        }
        @media ( min-width: 1015px ) {
            grid-template-areas:
            "body  links   tagline"
            "body  links   logo"
            "body  links   social";
        }
    }

    > a { grid-area: logo; }
    > section:first-of-type { grid-area: links; }
    > section:last-of-type { grid-area: social; }

    max-width: 100vw;
    overflow-x: hidden;

    padding: var(--spacing-medium) var(--page-padding-horizontal);
    background: var(--colour-background-accent-primary);
    color: var(--colour-text-tertiary);

    > a { margin-top: 5px; }

    a {
        color: var(--colour-text-tertiary) !important;
        [data-site='magazines'] & { color: inherit !important; }
        [data-site='events'] & { color: var(--colour-link) !important; }
        [data-site='main'] & { display: block; }
        white-space: normal !important;
        transition: all 0.35s ease-in-out;
        &:hover {
            color: var(--colour-text-accent-primary) !important;
            transition: all 0.35s ease-in-out;
        }
    }

    > ul,
    > section > ul {
        margin-top: 0;
        margin-bottom: 0;
    }


}

.Tagline {
    grid-area: tagline;
    [data-site='main'] & { display: none; }
    img {
        width: 138px;
    }
}

.Body {
    grid-area: body;
    [data-site='main'] & { display: none; }
    a {
        color: var(--colour-text-accent-tertiary) !important;
    }
    [data-site='magazines'] & {
        h1 {
            color: var(--colour-text-secondary) !important;
        }
    }
}

.Logos {
    grid-area: logo !important;
    > h1 {
        color: inherit;
    }
    > ul {
        display: flex;
        flex-direction: row;
        @media (max-width: 350px) {
            flex-direction: column;
            align-items: flex-start;
        }
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacing-small);
        margin: 0;
        padding: 0;
        > li {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }
    }
    [data-site='magazines'] & {
        svg, img {
            width: 138px;
            height: auto;
        }
        padding-bottom: var(--spacing-small) !important;
        @media ( min-width: 780px) {
            margin-top: -30px;
        }
    }
}

.Locations {

    grid-area: locations;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-grow: 0;
    list-style-type: none;
    padding: 0;
    gap: var(--spacing-medium);

    > li {
        margin: 0;
    }

    [data-site='events'] & ,
    [data-site='magazines'] & {
        display: none;
    }

}

.Subfooter {
    background: white;
    padding: 4px var(--page-padding-horizontal);
    > * {
        margin: 0;
        padding: 0;
        max-width: 100%;
        font-size: var(--font-size-tiny);
        display: inline;
        br { display: none; }
    }
    [data-site='events'] & ,
    [data-site='magazines'] & {
        display: none;
    }
}
