.Featured {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    a {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        font-size: var(--font-size-large);
        color: var(--colour-text-accent-primary);
    }
    p { padding-bottom: 0; }
    hr {
        width: 100%;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: var(--colour-text-accent-primary);
        margin-top: var(--spacing-small-three-quarters);
        margin-bottom: var(--spacing-small-three-quarters);
    }
    a:first-child {
        margin-bottom: var(--spacing-small-half);
    }
    a + p {
        margin-top: 0;
        font-size: var(--font-size-tiny);
    }
}

.Image {
   height: 250px;
   filter: var(--filter-shadow-mid);
   margin-bottom: var(--spacing-small-half);
}
