.HeaderParagraph {
    white-space: normal;
    > * {
        display: inline-block;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.Dates {
    white-space: nowrap;
    margin: var(--spacing-small-half) 0;
}

.VenueSummary {
    white-space: nowrap;
}

.Year {
    color: var(--ami-secondary-f);
    &::before {
        display: inline;
        content: ' ';
    }
}

.TagLine {
    font-weight: bold;
}

.Book {
    margin-top: var(--spacing-small) !important;
}

span.Book { display: none; }

ul.Links {
    display: flex;
    flex-direction: row;
    @media ( max-width: 360px ) {
        flex-direction: column;
    }
    align-items: flex-start;
    justify-content: flex-start;
    list-style-type: none;
    padding-left: 0;
    padding-bottom: 0;
    margin-top: var(--spacing-small);
    gap: var(--spacing-small);
    > li {
        display: inline-block;
        border: 0px;
        border-radius: 6px;
        cursor: pointer;
        flex: 0 0 auto;
        padding: 8px 14px 6px 14px;
        margin: 0;
        color: var(--colour-link);
        border: 1px solid var(--colour-link);
        background: none;
        transition: all 0.35s ease-in-out;
        font-size: var(--font-size-medium);

        &:hover {
            border: 1px solid var(--colour-link-active);
            color: var(--colour-link-active);
            transition: all 0.35s ease-in-out;
            filter: drop-shadow(0 0 8px var(--colour-link-glow));
        }
    }
}