.Logo {
    width: 69px;
    height: 28px;
    [data-site='events'] header & {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-y: hidden;
        height: 140px !important;
        max-height: 140px !important;
        width: auto !important;
        max-width: calc(100vw - 2*var(--page-padding-horizontal))!important;
        picture, img {
            height: 140px !important;
            width: auto !important;
            max-width: calc(100vw - 2*var(--page-padding-horizontal))!important;
            transform: none !important;
        }
        @media (max-width: 540px) {
            width: 80vw !important;
            height: auto !important;
            picture, img {
                height: auto !important;
                width: 80vw !important;
                transform: none !important;
            }
        }
    }
    [data-site='materials-energy-expo'] footer & {
        filter: grayscale(1) brightness(10);
        width: 138px;
        height: 56px;
    }
    a:hover & {
        img {
            transform: none !important;
        }
    }
    [data-site='magazines'] header & {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-y: hidden;
        height: 140px !important;
        max-height: 140px !important;
        width: 510px !important;
        max-width: 100vw !important;
        picture, img, svg {
            padding: var(--spacing-small) 0 !important;
            height: 100% !important;
            max-width: 100vw !important;
            width: auto !important;
            transform: none !important;
            @media( max-width: 600px ) {
                margin: 0 !important;
                //margin-left: 5% !important;
                height: auto !important;
                //max-width: 80vw !important;
                transform: none !important;
                align-self: stretch;
                justify-self: stretch;
            }
        }
    }
}
