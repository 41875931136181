@use 'styles/energy-heading' as *;
@use 'styles/magazine-heading' as *;
@use 'styles/magazine-button' as *;

.BodyContent {

    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--colour-text-tertiary);
    font-size: var(--font-size-medium);

    [data-site='main'] & h1 {
        font-size: var(--font-size-hero);
        margin: 0 0 1em 0;
        font-weight: normal;
        max-width: 400px;
        color: var(--colour-text-secondary);
    }

    [data-site='materials-energy-expo'] & h1 {
        @include energy-heading;
        margin-bottom: var(--spacing-medium);
    }

    [data-site='magazines'] & {
        color: var(--colour-text-primary);
        h1 {
            @include magazine-heading;
            margin-bottom: var(--spacing-small);
        }
    }

    h1, h2, h3, h4, h5, h6 {
        > a {
            color: var(--colour-text-accent-primary);
            &:hover { color: var(--colour-text-secondary); }
        }
    }

    p {
        [data-site='main'] & {
            margin-top: 0.5em;
            max-width: 400px;
        }
        [data-site='materials-energy-expo'] & {
            margin: 0;
        }
        a {
            color: var(--colour-text-accent-primary);
            &:hover { color: var(--colour-text-secondary); }
        }
    }

    > ul {
        padding-bottom: var(--spacing-medium);
        > li {
            list-style-type: none !important;
            > a { margin-left: 0 !important; }
        }
    }

    > section {
        height: 100%;
        > :last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    color: var(--colour-text-tertiary);
    > * {
        @media( min-width: 0px ) {
            padding: var(--spacing-small);
        }
        @media( min-width: 300px ) {
            padding: var(--spacing-medium);
        }

        [data-site='magazines'] & {
            @media( min-width: 0px ) {
                padding: 0;
            }
            @media ( min-width: 1100px ) {
                padding: var(--spacing-medium) 0;
            }
        }

        margin: 0;

        [data-site='main'] & {
            --background: var(--colour-background-accent-primary) !important;
            background: var(--background);
            &:nth-child(even) { --background: var(--colour-background-accent-primary) !important; }
        }

        li {
            list-style-type: square;
            &::marker {
                color: var(--colour-text-accent-primary);
            }
        }
    }
    [data-site='materials-energy-expo'] & > section {
        padding-left: 0 !important;
        padding-right: var(--spacing-medium) !important;
    }
    > section {
        p + ul { margin-top: calc( -1 * var(--spacing-small) ); }
    }

}

.Actions {
    padding: 0;
    [data-site='materials-energy-expo'] & ,
    [data-site='magazines'] & {
        margin-top: var(--spacing-small-half);
    }
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    [data-site='magazines'] & {
        gap: var(--spacing-small);
        li {
            margin-right: 0 !important;
        }
        @media ( max-width: 700px ) {
            flex-direction: column;
            align-items: center;
            justify-self: center;
            gap: var(--spacing-small-half);
            li {
                width: 100%;
            }
        }
    }
    li {
        list-style-type: none;
        margin-right: var(--spacing-medium);
    }
    --colour-link: var(--colour-text-tertiary) !important;
    --colour-link-active: var(--colour-text-accent-primary) !important;
    > section,
    > section > h1,
    > section > ul {
        display: contents;
        --colour-link: var(--colour-text-accent-primary);
        --colour-link-active: var(--colour-text-secondary);
        a:hover {
            color: var(--colour-link-active) !important;
        }
    }
    [data-site='magazines'] & {
        button {
            @include magazine-button;
            border: none;
        }
        button:nth-child(odd) {
            background: var(--colour-background-accent-primary);
        }
        button:nth-child(even) {
            background: var(--colour-background-accent-secondary);
        }
        a {
            @include magazine-button;
            border: none;
            margin-top: 0;
            padding: 20px 30px;
        }
        li:nth-child(odd) > a {
            background: var(--colour-background-accent-primary);
        }
        li:nth-child(even) > a {
            background: var(--colour-background-accent-secondary);
        }
    }
}

.SecondaryContent {
    position: relative;
    min-height: 400px;
    [data-site='materials-energy-expo'] & {
        @media( max-width: 1100px ) {
            display: none;
        }
    }
    picture {
        position: absolute;
        min-height: 400px;
        overflow: hidden;
        [data-site='materials-energy-expo'] & {
            border-bottom-left-radius: calc(2 * var(--spacing-medium));
        }
    }
    form { min-width: 100%; }

    [data-site='magazines'] & {
        @media ( max-width: 1100px ) {
            min-height: fit-content;
            picture {
                position: relative;
                min-height: fit-content;
            }
        }
    }
}
