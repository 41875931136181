.Browser {

    background: var(--colour-background-primary) !important;

    display: grid;
    gap: var(--spacing-small-half) var(--spacing-medium);

    @media ( min-width: 0 ) {
        grid-template-areas:
            "filters"
            "articles"
            "feeds";
    }

    @media ( min-width: 800px ) {
        grid-template-areas:
            "filters filters"
            "articles feeds";
        grid-template-rows: min-content 1fr;
        grid-template-columns: 1fr min-content;
    }

    align-items: flex-start;

    > header { grid-area: filters; }

}

.Filters[data-expanded='false'] {

    display: grid !important;
    justify-content: center;

    @media ( min-width: 0px ) {
        grid-template-areas:
            "type"
            "activity"
            "portfolio"
            "topic"
            "reset";
        grid-template-columns: 1fr;
        > button { justify-self: center; }
    }
    @media ( min-width: 650px ) {
        grid-template-areas:
            "type portfolio reset"
            "activity topic reset";
        grid-template-columns: min-content min-content min-content;
    }
    @media ( min-width: 1290px ) {
        grid-template-areas: "type activity portfolio topic reset";
        grid-template-columns: min-content min-content min-content min-content min-content;
        padding-left: var(--spacing-small);
        padding-right: var(--spacing-small);
    }

    > span {
        min-width: 100%;
        select { min-width: 100%; }
    }

}

.Filters[data-expanded='true'] {
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
    border: 1px solid black;
    > button { display: none; }
}

.TypeFilter { grid-area: type; }
.ActivityFilter { grid-area: activity; }
.PortfolioFilter { grid-area: portfolio; }
.TopicFilter { grid-area: topic; }

.Articles {

    grid-area: articles;
    // display: grid;
    // align-self: flex-start;
    // grid-template-columns: repeat(12,1fr);
    // grid-column-gap: var(--spacing-small);
    // grid-row-gap: var(--spacing-small);

    @media ( min-width: 0px ) {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-small);
    }
    // @media ( min-width: 650px ) {
    //     display: block;
    //     column-width: var(--card-min-width);
    //     column-gap: var(--spacing-small);
    // }

    @media( max-width: 800px ) {
        max-width: calc( 100vw - 2 * var(--page-padding-horizontal) );
    }

    padding-left: 0;
    margin: 0;

    align-items: stretch;
    justify-content: stretch;

    > li {

        list-style-type: none;
        max-width: var(--card-max-width);
        //break-inside: avoid;
        //margin-bottom: var(--spacing-small);

        > div {
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            > a {
                width: 100%;
                min-width: 100%;
            }
        }
        > *,
        > * > a {
            display: flex;
            flex-direction: column;
            //flex-grow: 1;
            font-size: var(--font-size-medium);
            > picture,
            > img {
                width: 100%;
                min-width: 100%;
                //height: auto !important;
                aspect-ratio: 1 / 1;
                margin-bottom: var(--spacing-small);
                filter: var(--filter-shadow-low);
                object-fit: contain;
            }
        }
        > * {
            border-top: 1px solid var(--colour-text-accent-primary);
            justify-content: stretch;
        }
        a + span {
            display: block !important;
        }
    }

}

.Feeds {

    grid-area: feeds;
    flex-direction: row;
    align-items: stretch;
    align-self: flex-start;

    padding: 0;
    margin: 0;

    > li > section {
        flex-grow: 1;
        padding: 0 !important;
        margin: 0 !important;
        height: min-content;
        > div > div {
            width: 100% !important;
            max-width: calc( 100vw - 2 * var(--page-padding-horizontal) ) !important;
            iframe {
                flex-grow: 0;
                width: 100% !important;
                max-width: calc( 100vw - 2 * var(--page-padding-horizontal) ) !important;
            }
        }
    }

    &:empty { display: none; }

    li { display: contents; }

}
