@import 'colours.scss';

@mixin magazine-button {
    margin-top: var(--spacing-small);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--colour-background-accent-primary);
    padding: 10px 30px;
    width: fit-content;
    color: var(--colour-text-secondary);
    border-radius: var(--spacing-small-half);
    text-transform: uppercase;
    text-wrap: wrap;
    p {
        margin: 0;
        padding: 0;
    }
    @media ( max-width: 500px ) {
        width: 100%;
    }
}