.ArticleList {

    display: grid;
    gap: var(--spacing-small);

    @media ( min-width: 0 ) {
        grid-template-areas:
            "header"
            "articles"
            "feeds";
        grid-template-columns: 1fr;
    }
    @media ( min-width: 800px ) {
        grid-template-areas:
            "header header"
            "articles feeds";
        grid-template-rows: min-content 1fr;
        grid-template-columns: 1fr min-content;
    }
    
    align-items: flex-start;

    padding-bottom: var(--spacing-medium) !important;

    > header > :last-child {
        margin-bottom: 0 !important;
    }

}

.Articles {

    grid-area: articles;
    margin: 0;
    padding: 0;
    display: grid;
    align-self: flex-start;
    width: 100%;
    grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
    gap: var(--spacing-small);
    padding-bottom: var(--spacing-small);
    @media( max-width: 800px ) {
        max-width: calc( 100vw - 2 * var(--page-padding-horizontal) );
    }
    > li {
        list-style-type: none;
        border-top: 1px solid var(--colour-text-accent-primary);
        border-bottom: 1px solid var(--colour-text-accent-primary);
        padding-bottom: var(--spacing-small);
        margin-bottom: calc( -1 * var(--spacing-small) - 1px );
    }

}

// .Feeds {

//     grid-area: feeds;
//     display: flex;
//     flex-direction: row;
//     align-items: stretch;
//     align-self: flex-start;
//     height: calc( 100% + 5 * var(--spacing-small) );
//     width: calc( 402px + var(--spacing-large) + var(--spacing-small) );
//     min-width: calc( 402px + var(--spacing-large) + var(--spacing-small) );
//     padding-bottom: var(--spacing-medium);
//     margin:
//         calc( -1 * var(--spacing-small) )
//         calc( -1 * var(--spacing-large) )
//         calc( -1 * var(--spacing-large) )
//         0;

//     &:empty { display: none; }

//     li { display: contents; }

// }

.Feeds {

    grid-area: feeds;
    flex-direction: row;
    align-items: stretch;
    align-self: flex-start;

    padding: 0;
    margin: 0;

    > li > section {
        flex-grow: 1;
        padding: 0 !important;
        margin: 0 !important;
        height: min-content;
        > div > div {
            width: 100% !important;
            max-width: calc( 100vw - 2 * var(--page-padding-horizontal) ) !important;
            iframe {
                flex-grow: 0;
                width: 100% !important;
                max-width: calc( 100vw - 2 * var(--page-padding-horizontal) ) !important;
            }
        }
    }

    &:empty { display: none; }

    li { display: contents; }

}