@use 'styles/magazine-button' as *;
.Summary {

    display: grid;

    @media( min-width: 0px ) {
        grid-template-areas:
            "header"
            "image"
            "details";
        gap: var(--spacing-small);
    }

    @media( min-width: 1100px ) {
        grid-template-areas:
            "image header"
            "image details";
        grid-template-columns: minmax(var(--card-min-width),500px) 1fr;
        gap: var(--spacing-small-half) var(--spacing-medium);
    }

    [data-site='injection-world'] & {
        @media( max-width: 600px ) {
            a {
                color: var(--colour-text-secondary) !important;
            }
        }
    }
    [data-site='magazines'] & {
        @media( max-width: 600px ) {
            gap: 0;
            .Image {
                display: none;
            }
            .Details {
                display: none;
            }
            a {
                @include magazine-button;
                background: var(--colour-background-accent-secondary);
                width: 80%;
                height: fit-content;
                margin-top: var(--spacing-small-half);
                @media ( max-width: 600px ) {
                    width: 100%;
                }
            }
        }

        @media( min-width: 600px ) {
            grid-template-areas:
                "image header"
                "image details";
            grid-template-columns: 200px 1fr;
            grid-template-rows: 30px 1fr;
            gap: 0 var(--spacing-small);
        }
    }

}

.Image {

    position: relative;
    grid-area: image;
    align-self: start;

    @media ( min-width: 0px ) { margin-bottom: 0; }
    @media ( min-width: 1100px ) { margin-bottom: var(--spacing-small) }

    > a,
    > span {
        grid-area: image;
        align-self: start;
        width: 100%;
        max-height: 280px;
        position: relative;
        padding: 0 !important;
        display: block;
        background: var(--colour-background-accent-primary);
        left: calc( -1 * var(--spacing-small-three-quarters) );
        top: var(--spacing-small-three-quarters);
        filter: var(--filter-shadow-low);
        [data-site='magazines'] & {
            background: none;
            filter: none;
            left: 0;
            top: 0;
        }
        > picture,
        > span {
            width: 100%;
            background: var(--colour-background-primary);
            max-height: 280px;
            > img { max-height: 280px; }
            svg { background: var(--colour-background-primary); }
            position: relative;
            z-index: var(--layer-body);
            padding: 0 !important;
            margin: 0 !important;
            left: var(--spacing-small-three-quarters);
            top: calc( -1 * var(--spacing-small-three-quarters) );
            [data-site='magazines'] & {
                left: 0;
                top: 0;
                background: none;
            }
        }
    }

}

.Header {
    grid-area: header;
    height: min-content;
    align-self: end;
    [data-site='magazines'] & {
        font-weight: bold;
        align-self: start;
        a {
            color: var(--colour-text-primary);
            &:hover { color: var(--colour-text-accent-primary); }
        }
    }
    white-space: nowrap;
    > h1 {
        margin: 0;
        font-size: var(--font-size-featured);
        white-space: normal;
        > a,
        > span {
            color: var(--colour-text-accent-secondary);
            &:hover { color: var(--colour-text-accent-primary); }
        }
    }
    > p {
        margin: 0;
        padding: 0;
        color: var(--colour-text-accent-secondary);
    }
}

.Details {
    grid-area: details;
    align-self: start;
    p {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    [data-site='magazines'] & {
        width: fit-content;
        ul {
            list-style-type: disc;
        }
    }
    details {
        margin-top: var(--spacing-small);
        summary {
            color: var(--colour-text-accent-primary);
            cursor: pointer;
        }
        h1, h2, h3, h4, h5, h6 {
            font-size: var(--font-size-medium);
            font-weight: bold;
        }
        > *:last-child {
            margin-bottom: 0;
            padding-bottom: var(--spacing-small-three-quarters);
        }
    }
}
