.SearchBox {

    align-self: center;
    height: min-content;

    [data-display-state='Search'] & {
        @media ( min-width: 0px ) {
            padding-bottom: var(--spacing-small-three-quarters) !important;
            margin: 0;
        }
        @media ( min-width: 525px ) {
            padding-bottom: 0;
            margin: 0 var(--page-padding-horizontal) 0 0;
            padding: 0;
        }
    }

    [data-display-state='Navigation'] & {
        background: transparent;
    }

    > form {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        @media( min-width: 350px ) {
            min-width: 270px;
        }
        border-radius: 100px;
        border: 1px solid var(--colour-text-accent-primary);
        :nth-child(1) { order: 2; }
        :nth-child(2) { order: 1; }
        :nth-child(3) { order: 3; }
        :nth-child(4) { order: 4; }
        > input,
        > button {
            border: none;
        }
        > span {
            display: none !important;
        }
        input {
            outline: none;
            margin-left: -1px;
            width: 100%;
            padding: 2px 0.5em 2px 2px;
            border-radius: 100px;
            background: transparent;
            font-size: var(--font-size-small);
            color: var(--colour-text-accent-primary);
            &::-webkit-search-cancel-button { display: none; }
            &[value=''] { margin-right: 30px; }
        }
        input + button {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            padding-left: 12px;
            padding-right: 8px
        }
        button {
            margin: 0;
        }
        input[value=''] + button + button {
            display: none;
        }
        input:not([value='']) + button + button {
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            padding-left: 8px;
            padding-right: 12px;
        }
        svg {
            fill: var(--colour-text-accent-primary);
            stroke: var(--colour-text-accent-primary);
        }
    }

}
