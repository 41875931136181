.Featured {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc( var(--spacing-small) + var(--spacing-small-half) ) !important;
    > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--font-size-medium);
    }
}

.Image {
    aspect-ratio: 16 / 10;
    position: relative;
    margin-bottom: 1em;
    filter: var(--filter-shadow-low);
}

.Metadata {
    position: absolute;
    top: 10px;
    left: 0;
}