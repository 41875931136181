@use 'styles/magazine-heading' as *;
@use 'styles/magazine-button' as *;

.Preview {
    display: grid;
    grid-template-areas:
        "Text Image";
    grid-template-columns: 1fr auto;
    @media( max-width: 700px ) {
        grid-template-columns: 100%;
    }
    gap: var(--spacing-large);
}

.Image {
    grid-area: Image;
    aspect-ratio: 7/10;
    @media( max-width: 700px ) {
        display: none;
    }
    max-height: 400px;
}

.Text {
    grid-area: Text;
    h1 {
        @include magazine-heading;
    }
    > p {
        margin-top: 0;
    }
    a {
        @include magazine-button;
        background: var(--colour-background-accent-secondary) !important;
    }
    p + ul {
        margin-top: calc(-1 * var(--spacing-small));
    }
}
