.Indicator {

    position: fixed;
    z-index: 10000;

    bottom: 1em;
    right: 1.25em;

    display: flex;
    flex-direction: row;

    background: hsl(0 100% 50%);
    opacity: 0.8;
    &:hover { opacity: 1.0; }
    transition: 0.35s all ease-in-out;

    padding: 0.5em 1em;
    border: red;
    border-radius: 2em;

    color: white;
    cursor: pointer;

}
