@use 'styles/magazine-heading' as *;
@use 'styles/magazine-button' as *;

.Issues {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);

    h1 {
        @include magazine-heading;
        margin-bottom: var(--spacing-small);
    }

    p {
        margin-bottom: 0;
    }

    > ul {

        margin-top: 0;
        @media (max-width: 495px) {
            margin-top: var(--spacing-small-half);
        }
        width: 100%;
        display: grid;
        list-style-type: none;

        grid-template-columns: repeat(auto-fit,minmax(200px,1fr));

        gap: var(--spacing-small);
        padding: 0;

        > li {
            list-style-type: none;
            min-height: 0;
            height: min-content;
        }
    }

}

.Links { 
    a {
        @include magazine-button;
    }

    p {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-small);
    }

    a:nth-child(odd) {
        background: var(--colour-background-accent-primary);
    }
    a:nth-child(even) {
        background: var(--colour-background-accent-secondary);
    }

    @media ( max-width: 500px ) {
        width: 100%;
        p {
            flex-direction: column;
        }
    }
}